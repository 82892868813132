import React from 'react';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';

import { CallToAction, Container, Link, Icon, formattedDate } from '@mattr/shared-components';
import { Layout } from '../components/layout/layout';

const ResourceList = ({ imageIcon, title, contentBody, metadata, author, url, isArticle, linkToVideo }) => (
  <li className="resources-item mb-10 lg:mb-20 h-auto">
    <Link to={url} attrs={{ target: '_blank' }}>
      <div className="flex">
        <div className={cx('resources-image mt-2 w-4/12 mr-6')}>
          {imageIcon && (
            <img
              src={imageIcon.url}
              alt=""
              aria-hidden
              className={cx({
                'w-full': linkToVideo,
                'icon--large': isArticle,
              })}
            />
          )}
        </div>
        <div className="w-3/4">
          <h3 className="font-semibold text-2xl">{title}</h3>
          <div className="mb-6">{author}</div>
          {contentBody && <ReactMarkdown source={contentBody} />}
          {metadata &&
            metadata?.map(({ date, organisation, timeToRead, id }) => (
              <div className="text-xs items-center flex flex-wrap mt-8" key={id}>
                {timeToRead && [
                  <Icon key="clock" name="clock" fill="none" />,
                  <span key="timeToReadSpan" className="ml-1 block">
                    {timeToRead} |
                  </span>,
                ]}
                {organisation && <span className="block ml-1"> {organisation} |</span>}
                {date && (
                  <span className="block ml-1" style={{ color: '#787878' }}>
                    {formattedDate(date)}
                  </span>
                )}
              </div>
            ))}
        </div>
      </div>
    </Link>
  </li>
);

const resourceNavTitles = [
  { title: 'Core Concepts', priority: 1, slug: 'core-concepts' },
  { title: 'Articles', priority: 2, slug: 'articles' },
  { title: 'Videos', priority: 3, slug: 'videos' },
];

export default function Resource({ data: { list, callToAction, navbar } }) {
  return (
    <Layout navbar={navbar}>
      <Container className="w-full lg:w-10/12 mx-auto pt-12">
        <div className="">
          <ul className="md:px-10 flex mx-auto w-full justify-between lg:mt-12 mb-12 lg:mb-20">
            {resourceNavTitles
              .sort((a, b) => a.priority - b.priority)
              .map(({ title, slug }) => (
                <li key={title}>
                  <Link
                    to={`/resources/${slug}`}
                    activeClassName="resource-list-active"
                    className="text-xl font-semibold"
                  >
                    {title}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <section>
          <div className="mx-auto">
            <ul>
              {list.listItems.map((item) => (
                <ResourceList key={item.id} {...item} />
              ))}
            </ul>
          </div>
        </section>
      </Container>
      <CallToAction {...callToAction} />
    </Layout>
  );
}

export const resourceQuery = graphql`
  query ResourceQuery($slug: String!, $parentSlug: String!, $category: String!) {
    navbar: datoCmsNavbar(category: { eq: $category }) {
      ... on DatoCmsNavbar {
        ...Navbar
      }
    }
    list: datoCmsList(slug: { eq: $slug }) {
      slug
      listName
      model {
        apiKey
      }
      id
      showListAsGrid
      linkToPage
      listItems {
        title
        id
        imageIcon {
          url
        }
        url
        contentBody
        isArticle
        author
        isGradient
        metadata {
          id
          organisation
          timeToRead
          date
        }
        backgroundColor
        linkToVideo
      }
    }
    pages: allDatoCmsList {
      edges {
        node {
          listName
          slug
        }
      }
    }
    callToAction: datoCmsCallToActionGlobal(page: { slug: { eq: $parentSlug } }) {
      page {
        slug
      }
      buttons {
        buttons {
          id
          title
          href
          openInNewWindow
          buttonStyle
        }
      }
      backgroundColour
      callToAction {
        title
        superscript
      }
      model {
        apiKey
      }
    }
  }
`;
